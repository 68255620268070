/*=================== contact banner start ====================*/

.dropdown .dropdown-menu {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.contact-info {
  margin-bottom: 25px;

  &__icon {
    margin-right: 20px;

    i,
    span {
      color: #8f9195;
      font-size: 27px;
    }
  }

  .media-body {
    h3 {
      font-size: 16px;
      margin-bottom: 0;
      font-size: 16px;
      color: #2a2a2a;
      a {
        &:hover {
          color: $theme-color2;
        }
      }
    }

    p {
      color: #8a8a8a;
    }
  }
}
/*=================== contact banner end ====================*/

/*=================== contact form start ====================*/
.contact-section {
  background: #ecf4fc;
}
.contact-title {
  display: inline-block;
  width: auto;
  font-size: 27px;
  font-weight: 600;
  margin-bottom: 20px;
  border: none;
  padding: 10px 20px 10px 19px;
  border-radius: 60px;
  color: #fff;
  background-size: 200%;
  box-shadow: 0px 7px 21px 0px rgba(0, 0, 0, 0.12);
  background-image: linear-gradient(to left, #559af3, #1462f3, #559af3);
}

.form-contact {
  padding: 30px 10px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(221, 221, 221, 1);

  label {
    font-size: 14px;
  }

  .file-input-group {
    label {
      position: relative;
      &:hover {
        color: $theme-color;
      }
      &:hover > .file-upload-info {
        visibility: visible;
        opacity: 1;
        transition: all 0.5s;
      }
    }
    .file-upload-info {
      position: absolute;
      background: #fff;
      padding-left: 20px;
      padding-top: 16px;
      box-shadow: 0 0 10px rgba(221, 221, 221, 1);
      opacity: 0;
      width: 222px;
      left: 30px;
      top: 30px;
      visibility: hidden;
      z-index: 99;
    }
    input {
      padding: 0 !important;
      &::-webkit-file-upload-button {
        height: 100%;
        border: none;
        background: #ecf4fc;
      }
    }
  }

  .form-group {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column-reverse;
    > span {
      width: fit-content;
      color: black;
      line-height: 20px;
      font-size: 14px;
    }
  }

  .form-control {
    border: 1px solid #e5e6e9;
    border-radius: 0px;
    height: 48px;
    padding-left: 18px;
    font-size: 13px;
    background: transparent;

    &:focus {
      outline: 0;
      box-shadow: none;
      border-color: $theme-color;
      + span {
        color: $theme-color;
        background-color: #fff;
        padding: 0 5px;
        transform: translate(15px, 10px);
        visibility: visible;
        transition: all 0.5s;
      }
    }

    &::placeholder {
      font-weight: 300;
      color: #999999;
    }
  }

  textarea {
    border-radius: 0px;
    height: 100% !important;
  }

  // button{
  //   border: 0;
  // }
}

/*=================== contact form end ====================*/

/* Contact Success and error Area css
============================================================================================ */

.modal-message {
  .modal-dialog {
    position: absolute;
    top: 36%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) !important;
    margin: 0px;
    max-width: 500px;
    width: 100%;
    .modal-content {
      .modal-header {
        text-align: center;
        display: block;
        border-bottom: none;
        padding-top: 50px;
        padding-bottom: 50px;
        .close {
          position: absolute;
          right: -15px;
          top: -15px;
          padding: 0px;
          color: #fff;
          opacity: 1;
          cursor: pointer;
        }
        h2 {
          display: block;
          text-align: center;
          padding-bottom: 10px;
        }
        p {
          display: block;
        }
      }
    }
  }
}
.contact-section {
  padding: 2.188rem 0 100px;
  @media #{$tab} {
    padding: 2.188rem 0 40px;
  }
  @media #{$medium_device} {
    padding: 2.188rem 0 50px;
  }
  .btn_2 {
    background-color: #191d34;
    padding: 18px 60px;
    border-radius: 50px;
    margin-top: 0;
    &:hover {
      background-color: $theme-color2;
    }
  }
}
