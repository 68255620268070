$offset: -10px;
$count: 10;
$duration: 0.3;
@keyframes subject {
  from {
    transform: translateY(0);
    color: white;
  }
  to {
    transform: translateY($offset);
  }
}

.cd-words-wrapper {
  color: #0f61ee;
  b {
    display: inline-block;
    position: relative;
    animation-duration: #{$duration}s;
    animation-name: subject;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
}

@for $i from 0 to $count {
  .cd-words-wrapper :nth-child(#{$count}n + #{$i}) {
    animation-delay: -#{($count - $i) * 2 * $duration / $count}s;
  }
}

.slider-height {
  padding-top: 50px;
  padding-bottom: 30px;
  min-height: calc(100vh - 82px);
}

.slider-height2 {
  height: 450px;
  background-size: cover;
  @media #{$md} {
    height: 350px;
  }
  @media #{$sm} {
    height: 260px;
  }
  @media #{$xs} {
    height: 260px;
  }
}
.slider-area2 {
  position: relative;
  background: -webkit-linear-gradient(0deg, #0f61ef80 0%, #4c9afe80 100%);
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  &::before,
  &::after {
    content: "";
    width: 60vmax;
    height: 60vmax;
    position: absolute;
    background: rgba(#eee, 0.1);
    left: 10vmin;
    top: auto;
    animation: morph 7s linear infinite alternate, spin 10s linear infinite;
    z-index: 1;
    will-change: border-radius, transform;
    transform-origin: 55% 55%;
    pointer-events: none;
    overflow: hidden;
  }

  &::after {
    width: 60vmin;
    height: 60vmin;
    left: auto;
    overflow: hidden;
    right: 10vmin;
    top: auto;
    bottom: 0;
    animation: morph 5s linear infinite alternate,
      spin 13s linear infinite reverse;
    transform-origin: 20% 20%;
  }
}

@keyframes morph {
  0% {
    border-radius: 40% 60% 60% 40% / 70% 30% 70% 30%;
  }
  100% {
    border-radius: 40% 60%;
  }
}

@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}

.slider-area {
  margin-top: -1px;
  background-image: url(../img/gallery/pic6.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  .hero__caption {
    overflow: hidden;
    & > span {
      font-size: 18px;
      font-family: $font_2;
      font-weight: 600;
      margin-bottom: 5px;
      text-transform: uppercase;
      color: #396cf0;
      display: inline-block;
      position: relative;
      letter-spacing: 0.05em;
      @media #{$xs} {
        font-size: 13px;
      }
    }
    h1 {
      font-size: 50px;
      font-weight: 900;
      margin-bottom: 14px;
      color: #102039;
      line-height: 1.2;
      margin-bottom: 30px;
      text-transform: uppercase;
      @media #{$lg} {
        font-size: 50px;
        line-height: 1.2;
      }
      @media #{$md} {
        font-size: 50px;
        line-height: 1.2;
      }
      @media #{$sm} {
        font-size: 35px;
        line-height: 1.4;
      }
      @media #{$xs} {
        font-size: 29px;
        line-height: 1.5;
      }
    }
    p {
      font-size: 18px;
      color: #212025;
      font-family: $font_2;
      margin-bottom: 50px;
      padding-right: 35px;
      @media #{$md} {
        padding-right: 13px;
      }
      @media #{$sm} {
        padding-right: 0px;
      }
      @media #{$xs} {
        padding-right: 0px;
        font-size: 15px;
      }
    }
  }
}

// Hero Overly
.hero-overly {
  position: relative;
  z-index: 1;
  &::before {
    position: absolute;
    content: "";
    background-color: rgba(1, 10, 28, 0.3);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    background-repeat: no-repeat;
  }
}

//  Transparent Header
.header-transparent {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9;
}

// Another Hero Page
.slider-area2 {
  .hero-cap {
    h2 {
      color: #102039;
      font-size: 50px;
      font-weight: 800;
      text-transform: capitalize;
      line-height: 1;
      @media #{$sm} {
        font-size: 50px;
      }
      @media #{$xs} {
        font-size: 35px;
      }
    }
  }
}
