
.gallery-area{
    .single-gallery{
        position: relative;
        overflow: hidden;
        &::before{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 100%;
            width: 100%;
            content: "";
            background-color: rgba(1,10,28,0.3);
            opacity: 0;
            z-index: 1;
            @include transition(.4s);
        }
        &:hover{
            &::before{
                opacity: 1;
                visibility:visible;
                }
        }
        .gallery-img{
            transform:scale(1.06);
            @include transition(.4s);
        }
        // img
        .big-img{
            position: relative;
            height: 690px;
            background-size: cover;
            background-repeat: no-repeat;
        }
        .small-img{
            position: relative;
            height: 330px;
            background-size: cover;
            background-repeat: no-repeat;
        }

    }
}


.single-gallery:hover .gallery-img{
    transform:scale(1);
   }


