/* 21. Contact */
.contact-form-main{
    position: relative;
    //Left Img
    .from-left{
        overflow: hidden;
        img{
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
    .form-tittle{
        padding: 111px 51px 0px 67px;
        @media #{$xs}{
            padding: 50px 10px 0px 10px;
        }
    }
.form-wrapper{
    background: #f7fdff;
}
    #contact-form{
        padding: 61px 51px 115px 69px;
        @media #{$xs}{
            padding: 13px 25px 76px 25px;
        }
        @media #{$sm}{
            padding: 13px 25px 76px 25px;
        }
        position: relative;
        input {
            background:none;
            height: 60px;
            width: 100%;
            padding: 10px 25px;
            padding-right: 30px;
            border: 0;
            color: #112957;
            font-weight: 500;
            text-transform: capitalize;
            border-radius: 0px;
            border-bottom: 2px solid #e9f0f4;
            &::-moz-placeholder {
                color: #112957;
                font-size: 16px;
                font-weight: 300;
                transition: .4s;
            }
            &:focus::-webkit-input-placeholder {
                opacity: 0;
                -webkit-transition: .4s;
                -o-transition: .4s;
                transition: .4s;
            }            
            .list {
                width: 100%;
                border-radius: 0;
            }
        }
        .nice-select {
            background: none;
            height: 60px;
            width: 100%;
            border: 0;
            color: #112957;
            font-weight: 300;
            font-size: 16px;
            text-transform: capitalize;
            padding-top: 10px;
            border-radius: 0px;
            border-bottom: 2px solid #e9f0f4;
            &::after {
                border-bottom: 2px solid #a3a3a3;
                border-right: 2px solid #a3a3a3;
                height: 7px;
                right: 41px;
                width: 7px;
            }
            &.open .list {
                width: 100%;
                border-radius: 0;
            }
            .option {
                color: #a3a3a3;
            }
        }
        textarea {
            color: #112957;
            background: none;
            font-weight: 500;
            letter-spacing: 2px;
            text-transform: capitalize;
            height: 130px;
            width: 100%;
            padding: 30px 21px;
            border: 0;
            border-radius: 0px;
            border-bottom: 2px solid #e9f0f4;
            @media #{$md}{
                padding: 30px 42px;
            }
            @media #{$sm}{
                padding: 30px 24px;
            }
            @media #{$xs}{
                padding: 30px 25px;
            }
            &::placeholder {
                color: #112957;
                font-size: 16px;
                font-weight: 300;
                transition: .4s;
            }
        }

    }   
}

.contact {
    border: 1px solid #eaedff;
    padding: 40px 10px;
    & i {
        background: #8fb569;
        font-size: 40px;
        height: 150px;
        width: 150px;
        color: #ffffff;
        border-radius: 50%;
        line-height: 135px;
        border: 10px solid #fff;
        -webkit-box-shadow: 0px 16px 32px 0px rgba(206, 226, 255, 0.4);
        box-shadow: 0px 16px 32px 0px rgba(206, 226, 255, 0.4);
        margin-bottom: 30px;
    }
    & h3 {
        font-size: 26px;
        margin-bottom: 15px;
    }
    & p {
        margin: 0;
        padding: 0 50px;
    }
}


    

    



