.department_area {
  background: #ecf4fc;
  padding-bottom: 80px;
  .row.custom_align {
    margin-bottom: 60px;
  }
  .learn_more_btn {
    @media #{$mobile_device} {
      text-align: left !important;
      margin-top: 30px;
    }
  }
  .dept_main_info {
    box-shadow: 0px 20px 30px 0px rgb(221, 221, 221);
    .dept_thumb {
      img {
        width: 100%;
        height: 500px;
        @media #{$mid_device} {
          max-width: 100%;
        }
      }
    }
    .dept_info {
      background-image: -moz-linear-gradient(
        0deg,
        rgb(36, 192, 241) 0%,
        rgb(76, 154, 254) 100%
      );
      background-image: -webkit-linear-gradient(
        0deg,
        rgb(36, 192, 241) 0%,
        rgb(76, 154, 254) 100%
      );
      background-image: -ms-linear-gradient(
        0deg,
        rgb(36, 192, 241) 0%,
        rgb(76, 154, 254) 100%
      );
      background-image: linear-gradient(
        0deg,
        rgb(36, 192, 241) 0%,
        rgb(76, 154, 254) 100%
      );
      padding: 80px 103px 104px 111px;
      @media #{$lg} {
        padding: 78px 103px 52px 111px;
      }
      @media #{$md} {
        padding: 78px 103px 52px 111px;
      }
      @media #{$sm} {
        padding: 71px 18px 60px 23px;
      }
      @media #{$xs} {
        padding: 71px 18px 60px 23px;
      }
      .divider {
        width: 70%;
        border: 2px solid #ecf4fc;
        border-radius: 4px;
      }
      h3 {
        font-size: 25px;
        line-height: 35px;
        color: #fff;
        font-weight: 600;
        font-family: $font_1;
        @media #{$mobile_device} {
          font-size: 20px;
        }
        br {
          @media #{$mid_device} {
            display: none;
          }
          @media #{$xs} {
            display: none;
          }
        }
      }
      p,
      b {
        margin-bottom: 30px;
        margin-top: 20px;
        color: #fff;
        @media #{$mid_device} {
          margin-top: 15px;
          margin-bottom: 20px;
        }
      }
      li {
        color: #fff;
        font-size: 14px;
      }
    }
  }
  .depart_ment_tab {
    justify-content: center;
    .service {
      margin-bottom: 30px;
      a {
        position: relative;
        overflow: hidden;
        padding: 0;
        width: 100%;
        min-height: 200px !important;
        border-radius: 2px;
        background-size: cover !important;
        background-position: center !important;
        text-align: center;
        @include border-radius(3px);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-shadow: 0px 10px 20px 0px rgba(221, 221, 221, 0.3);
        @include transition(0.3s);
        @media #{$mobile_device} {
          min-height: 80px;
        }
        h4 {
          position: absolute;
          bottom: 0;
          text-align: center;
          width: 100%;
          padding: 10px;
          background: white;
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 0;
          @media #{$mobile_device} {
            margin-bottom: 0;
          }
          @media #{$mid_device} {
            font-size: 18px;
          }
          br {
            @media #{$mid_device} {
              display: none;
            }
          }
        }
        &:hover {
          border: 1px solid rgba(15, 97, 239, 0.6);
          box-shadow: 0px 10px 20px 0px rgba(15, 97, 239, 0.6);
          h4 {
            color: $theme_color;
          }
        }
      }
    }
  }
}
