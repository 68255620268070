// Font Family
    $font_1 :'Poppins', sans-serif;   //Heading Font
    $font_2 :'Poppins', sans-serif;     //Body Font 
    // $font_3 :'Muli', sans-serif;   //special fonts

/*--------------- ---- Responsive --------------------------*/

    $laptop: 'only screen and (min-width: 1200px) and (max-width: 1600px)';
    $lg :'only screen and (min-width: 992px) and (max-width: 1199px)';
    $md:'only screen and (min-width: 768px) and (max-width: 991px)';
    $sm: 'only screen and (min-width: 576px) and (max-width: 767px)';
    $xs:'(max-width: 575px)';
// Extra Responsive

    $medium_device : 'only screen and (min-width: 992px) and (max-width: 1200px)';
    $tab_device:'only screen and (min-width: 768px) and (max-width: 991px)';
    $large_mobile: 'only screen and (min-width: 576px) and (max-width: 767px)';
    $tab: '(max-width: 991px)';
    $tab2: '(max-width: 1199px)';
    $small_mobile:'(max-width: 576px)';
    $xs_mobile:'(max-width: 420px)';
    $sm_mobile:'only screen and (min-width: 421px) and (max-width: 575px)';
    $big_screen:'only screen and (min-width: 1200px) and (max-width: 1440px)';
    $big_screen2:'only screen and (min-width: 1200px) and (max-width: 1300px)';
    $big_screen3:'only screen and (min-width: 1441px) and (max-width: 1500px)';
    $big_screen4:'only screen and (min-width: 1501px) and (max-width: 1750px)';
    $extra_big_screen: 'only screen and (min-width: 1200px) and (max-width: 3640px)';


// More Extra Responsive
    $large_device_2:'(min-width: 1501px) and (max-width: 1700px)';
    $mid_device:'(min-width: 992px) and (max-width: 1200px)';
    $tablet_device:'(min-width: 768px) and (max-width: 991px)';
    $mobile_device:'(max-width: 767px)';
    $large_mobile:'only screen and (min-width: 480px) and (max-width: 767px)';

// Custom
    $large1:'only screen and (min-width: 1200px) and (max-width: 1360px)';
    $large2:'(min-width: 1200px) and (max-width: 1500px)';
    $large3:'only screen and (min-width: 1601px) and (max-width: 1800px)';



@import 'color';

// Elemet Page btn
$heading_color2: #112e41;
$white_color:#fff;

.boxed-btn {
	background: #fff;
    color: $theme-color !important;
    
	display: inline-block;
	padding: 18px 44px;
	font-family: $font_1;
	font-size: 14px;
    font-weight: 400;
    border: 0;
    border: 1px solid $theme-color;
    letter-spacing: 3px;

    text-align: center;
    color: $theme-color;
    text-transform: uppercase;
    cursor: pointer;
    &:hover{
        background: $theme-color;
        color: #fff !important;
        border: 1px solid $theme-color;
    }
    &:focus{
        outline: none;
    }
    &.large-width{
        width: 220px;
    }
}



