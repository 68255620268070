	/*
  	Flaticon icon font: Flaticon
  	Creation date: 14/01/2020 06:46
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("../../fonts/Flaticon.eot");
  src: url("../../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../../fonts/Flaticon.woff2") format("woff2"),
       url("../../fonts/Flaticon.woff") format("woff"),
       url("../../fonts/Flaticon.ttf") format("truetype"),
       url("../../fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../../fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
font-style: normal;
}

.flaticon-verified:before { content: "\f100"; }
.flaticon-teeth:before { content: "\f101"; }
.flaticon-cardiovascular:before { content: "\f102"; }
.flaticon-ear:before { content: "\f103"; }
.flaticon-bone:before { content: "\f104"; }
.flaticon-lung:before { content: "\f105"; }
.flaticon-cell:before { content: "\f106"; }
.flaticon-doctor:before { content: "\f107"; }
.flaticon-find:before { content: "\f108"; }
.flaticon-customer-service:before { content: "\f109"; }
.flaticon-call:before { content: "\f10a"; }