.navbar {
    justify-content: flex-start !important;
    box-shadow: 0 5px 10px 0 rgba(221, 221, 221, 1);
}
.navbar-toggler {
    color: $theme-color2 !important;
    border-color: transparent !important;
    i {
        font-size: 28px;
    }
    &:focus {
        outline: none;
    }
}
.navbar-brand {
    padding-left: 30px !important;
    margin: 10px auto;
    .brand {
        font-size: 24px;
        font-weight: 700;
        color: $theme-color;
        margin: 0;
    }
    // small {
    //     font-size: 12px;
    //     margin: 0;
    //     color: $body-text-color;
    // }
}
.main-menu{
    padding: 0px 130px;
    @media #{$laptop}{
        padding: 0px 30px;
        margin-right: 30px;
    }
    @media #{$lg}{
        padding: 10px 15px;
    }
    // mobile menu show
    @media #{$md}{
        padding: 10px 50px;
    }
    @media #{$sm}{
        padding: 10px 20px;
    }
    @media #{$xs}{
        padding: 10px 14px;
    }
    & ul{
        @media #{$laptop}{
            justify-content: flex-end !important;
        }
        & li{
            display: inline-block;
            position: relative;
            z-index: 1;

            & a{
                color: #102039;
                font-weight: 500;
                padding: 10px 27px;
                display: block;
                font-size: 16px;
                @include transition(.3s);
                text-transform: capitalize;
                position: relative;
                font-family: $font_2;
                @media #{$lg}{
                    padding: 10px 15px;
                }
            }
            &:hover{
                & > a{
                    color:$theme-color;
                }
            }
        }
        & ul.submenu{
            position: absolute;
            min-width: 300px;
            background: #fff;
            right: -100px;
            visibility: hidden;
            opacity: 0;
            box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.05);
            padding: 17px 0;
            overflow-y: auto;
            justify-content: flex-start;
            @include transition(.3s);
            .submenu-title{
                font-weight: 400;
                color:#aaa;
                &:hover{
                    color: #aaa;
                }
            }
            & > li{
                display: block;
                & > a{
                    padding: 5px 10px !important;
                    font-size: 14px;
                    text-transform: capitalize;
                    margin: 0;
                    &:hover{
                        color:$theme-color;
                        background: none;
                    }
                }
            } 
        }
    }
}

//! SubMenu Hover
.main-menu ul ul.submenu > li > a:hover:not([disabled]) {
        padding-left: 13px !important;
}
.main-menu ul > li:hover > ul.submenu {
    visibility: visible;
    opacity: 1;
    // top: 100%;
}